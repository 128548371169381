import PropTypes from 'prop-types';
import { createContext, useEffect, useReducer } from 'react';
import { useNavigate } from 'react-router-dom';

// third-party
import { Chance } from 'chance';
import jwtDecode from 'jwt-decode';

// reducer - state management
import { LOGIN, LOGOUT } from 'store/actions';
import accountReducer from 'store/accountReducer';

// project imports
import { axiosVonto, axiosUserAccess, axiosCatalog, axiosPeople, axiosParcelsAPI } from 'hooks/axiosInstance';
import Loader from 'ui-component/Loader';
import tokenService from 'hooks/tokenService';
// import axiosUserAccess from 'hooks/axiosUserInstance';
// import axios from 'hooks/axiosInstance';

const chance = new Chance();

// constant
const initialState = {
    isLoggedIn: false,
    isInitialized: false,
    user: null
};

const verifyToken = (serviceToken) => {
    if (!serviceToken) {
        return false;
    }
    const decoded = jwtDecode(serviceToken);
    // console.log('decoded', decoded);
    /**
     * Property 'exp' does not exist on type '<T = unknown>(token, options?: JwtDecodeOptions | undefined) => T'.
     */
    return decoded.exp > Date.now() / 1000;
    // return decoded;
};

const setSession = (serviceToken) => {
    if (serviceToken) {
        tokenService.setToken(serviceToken);
        axiosVonto.setToken(serviceToken);
        axiosUserAccess.setToken(serviceToken);
        axiosCatalog.setToken(serviceToken);
        axiosPeople.setToken(serviceToken);
        axiosParcelsAPI.setToken(serviceToken);
        // axios.defaults.headers.common.Authorization = `Bearer ${serviceToken}`;
    } else {
        tokenService.setToken(null);
        axiosVonto.setToken(null);
        axiosUserAccess.setToken(null);
        axiosCatalog.setToken(null);
        // axiosPeople.setToken(null);
        // delete axios.defaults.headers.common.Authorization;
    }
};

// ==============================|| JWT CONTEXT & PROVIDER ||============================== //
const JWTContext = createContext(null);

export const JWTProvider = ({ children }) => {
    const navigate = useNavigate();
    const [state, dispatch] = useReducer(accountReducer, initialState);
    useEffect(() => {
        const init = async () => {
            try {
                const serviceToken = window.localStorage.getItem('serviceToken');
                if (serviceToken && verifyToken(serviceToken)) {
                    setSession(serviceToken);
                    // const response = await axios.get('/api/account/me');
                    const decode = jwtDecode(serviceToken);
                    // console.log('decodeed jwt', decode);
                    const user = {
                        email: decode.email,
                        name: decode.unique_name,
                        role: decode.role,
                        idUser: decode.Id,
                        details: decode.UserDetails ? JSON.parse(decode.UserDetails) : null,
                        profilePic: decode.ProfilePicture
                    };
                    // console.log('auth context user', user);

                    dispatch({
                        type: LOGIN,
                        payload: {
                            isLoggedIn: true,
                            user
                        }
                    });
                } else {
                    console.log('no service token');
                    dispatch({
                        type: LOGOUT
                    });
                }
            } catch (err) {
                console.log('login err', err);
                dispatch({
                    type: LOGOUT
                });
            }
        };

        init();
    }, []);

    const login = async (userName, password) => {
        const { data } = await axiosUserAccess.instance.post('/api/Auth/token', {
            userName,
            password
        });

        const token = data.token;
        setSession(data.token);

        const decode = jwtDecode(token);
        const user = {
            email: decode.email,
            name: decode.unique_name,
            role: decode.role,
            idUser: decode.Id,
            details: decode.UserDetails ? JSON.parse(decode.UserDetails) : null,
            profilePic: decode.ProfilePicture
        };

        console.log('user info', user);
        dispatch({
            type: LOGIN,
            payload: {
                isLoggedIn: true,
                user
            }
        });
    };

    const register = async (email, password, firstName, lastName) => {
        // todo: this flow need to be recode as it not verified
        const id = chance.bb_pin();
        const response = await axiosUserAccess.instance.post('/api/account/register', {
            id,
            email,
            password,
            firstName,
            lastName
        });
        let users = response.data;

        if (window.localStorage.getItem('users') !== undefined && window.localStorage.getItem('users') !== null) {
            const localUsers = window.localStorage.getItem('users');
            users = [
                ...JSON.parse(localUsers),
                {
                    id,
                    email,
                    password,
                    name: `${firstName} ${lastName}`
                }
            ];
        }

        window.localStorage.setItem('users', JSON.stringify(users));
    };

    const logout = () => {
        setSession(null);
        dispatch({ type: LOGOUT });
        navigate('/login');
    };

    const forgotPassword = (email) => {
        console.log(state);
        const response = axiosUserAccess.instance.post(`/api/Account/ForgotPassword/${email}`);
        return response;
    };

    const updatePasswordProfile = (newPassword) => {
        // const body = {newPassword: newPassword}
        // const { user } = state;
        // const idUser = user.idUser;

        const response = axiosUserAccess.instance.post(`/api/Account/ChangePassword/`, { newPassword });
        return response;
    };

    const updateProfile = () => {
        console.log(state);
        return state;
        // const response = axios.put('/api/userAccess/Users/password', { email, newPassword });
        // return response;
    };

    if (state.isInitialized !== undefined && !state.isInitialized) {
        return <Loader />;
    }

    return (
        <JWTContext.Provider value={{ ...state, login, logout, register, forgotPassword, updateProfile, updatePasswordProfile }}>
            {children}
        </JWTContext.Provider>
    );
};

JWTProvider.propTypes = {
    children: PropTypes.node
};

export default JWTContext;
